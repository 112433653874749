.g-4 {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.g-4.fade-in {
    opacity: 1;
}

.h-100 {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.h-100.fade-in {
    opacity: 1;
    transform: translateY(0);
}